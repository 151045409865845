import React from 'react'
import Icon from './icon'

import style from '../styles/media.module.css'

const twitterIcon = `M48 10.613c-1.763 0.787-3.666 1.313-5.653 1.547 2.034-1.219 3.591-3.15 4.331-5.447-1.903 1.125-4.012 1.95-6.253 2.391-1.8-1.912-4.359-3.103-7.191-3.103-5.438 0-9.844 4.406-9.844 9.844 0 0.769 0.084 1.519 0.253 2.241-8.184-0.413-15.441-4.331-20.297-10.294-0.844 1.453-1.331 3.15-1.331 4.95 0 3.413 1.734 6.431 4.378 8.194-1.613-0.047-3.131-0.497-4.463-1.228 0 0.038 0 0.084 0 0.122 0 4.772 3.394 8.756 7.903 9.656-0.825 0.225-1.697 0.347-2.597 0.347-0.637 0-1.247-0.066-1.856-0.178 1.256 3.909 4.894 6.759 9.197 6.844-3.375 2.644-7.612 4.219-12.234 4.219-0.797 0-1.575-0.047-2.353-0.141 4.369 2.803 9.544 4.425 15.103 4.425 18.113 0 28.022-15.009 28.022-28.022 0-0.431-0.009-0.853-0.028-1.275 1.922-1.378 3.591-3.113 4.912-5.091z`
const linkedInIcon = `M43.5 0h-39c-2.475 0-4.5 2.025-4.5 4.5v39c0 2.475 2.025 4.5 4.5 4.5h39c2.475 0 4.5-2.025 4.5-4.5v-39c0-2.475-2.025-4.5-4.5-4.5zM18 39h-6v-21h6v21zM15 15c-1.659 0-3-1.341-3-3s1.341-3 3-3c1.659 0 3 1.341 3 3s-1.341 3-3 3zM39 39h-6v-12c0-1.659-1.341-3-3-3s-3 1.341-3 3v12h-6v-21h6v3.722c1.237-1.697 3.131-3.722 5.25-3.722 3.731 0 6.75 3.356 6.75 7.5v13.5z`
const githubIcon = `M24 0.593c-13.253 0-24 10.745-24 24 0 10.604 6.877 19.6 16.413 22.774 1.199 0.222 1.64-0.521 1.64-1.155 0-0.572-0.023-2.463-0.033-4.468-6.677 1.452-8.086-2.832-8.086-2.832-1.091-2.774-2.665-3.512-2.665-3.512-2.177-1.49 0.165-1.459 0.165-1.459 2.409 0.169 3.679 2.473 3.679 2.473 2.141 3.669 5.614 2.608 6.985 1.995 0.215-1.551 0.837-2.61 1.524-3.209-5.331-0.607-10.935-2.665-10.935-11.861 0-2.62 0.938-4.761 2.473-6.442-0.249-0.605-1.071-3.046 0.232-6.352 0 0 2.016-0.645 6.602 2.46 1.914-0.532 3.967-0.799 6.007-0.808 2.039 0.009 4.094 0.276 6.012 0.808 4.581-3.105 6.593-2.46 6.593-2.46 1.306 3.306 0.485 5.747 0.236 6.352 1.538 1.681 2.47 3.822 2.47 6.442 0 9.218-5.615 11.248-10.959 11.842 0.861 0.745 1.628 2.205 1.628 4.444 0 3.211-0.028 5.796-0.028 6.586 0 0.639 0.432 1.387 1.649 1.152 9.531-3.177 16.399-12.17 16.399-22.77 0-13.255-10.745-24-24-24z`
const mediumIcon = `M0 0v48h48v-48zM39.876 11.372l-2.574 2.468c-0.181 0.139-0.297 0.355-0.297 0.598 0 0.044 0.004 0.088 0.011 0.13l-0.001-0.005v18.134c-0.007 0.037-0.010 0.080-0.010 0.124 0 0.243 0.115 0.459 0.294 0.597l0.002 0.001 2.514 2.468v0.542h-12.644v-0.54l2.604-2.53c0.256-0.256 0.256-0.33 0.256-0.72v-14.66l-7.24 18.39h-0.98l-8.432-18.39v12.326c-0.010 0.069-0.015 0.148-0.015 0.229 0 0.461 0.184 0.879 0.482 1.186l-0-0 3.388 4.108v0.542h-9.604v-0.54l3.39-4.11c0.284-0.295 0.459-0.696 0.459-1.138 0-0.097-0.008-0.193-0.025-0.286l0.001 0.010v-14.252c0.004-0.038 0.006-0.082 0.006-0.127 0-0.367-0.159-0.698-0.411-0.926l-0.001-0.001-3.012-3.628v-0.54h9.348l7.226 15.846 6.352-15.848h8.912z`
const devIcon = `M14.84 20.1c-0.36-0.32-0.92-0.46-1.68-0.46h-1.16l0.040 4.88 0.080 4.9 1.12-0.040c0.82 0 1.26-0.14 1.66-0.52 0.48-0.48 0.52-0.72 0.52-4.4 0-3.82-0.040-3.92-0.58-4.36zM0 9.88v28.24h48v-28.24zM17.12 30.6c-0.88 1.16-2.12 1.54-5.060 1.54h-2.64v-15.080h2.8c3.34 0 4.32 0.36 5.2 1.8 0.54 0.86 0.58 1.2 0.64 5.14 0.1 4.46-0.040 5.46-0.94 6.6zM27.3 19.66h-4.94v3.54h3.040v2.56l-1.44 0.080-1.5 0.060v3.54l2.44 0.060 2.4 0.080v2.56h-3.2c-3.060 0-3.2-0.020-3.74-0.6l-0.6-0.56v-6.32c0-6.040 0.020-6.36 0.5-6.96 0.46-0.62 0.5-0.62 3.76-0.62h3.28zM36.66 30.56c-0.34 0.86-1.28 1.58-2 1.58-0.36 0-0.9-0.3-1.34-0.78-0.64-0.64-0.9-1.26-1.64-4.16l-1.8-6.78-0.9-3.34h1.52c0.8 0 1.5 0.040 1.5 0.1 0 0.12 2.32 9.080 2.52 9.66 0.080 0.3 0.64-1.4 1.46-4.6l1.32-5.040 1.48-0.080c0.8-0.040 1.46 0 1.46 0.080 0 0.28-3.34 12.76-3.58 13.36z`

const Media = () => {

  return (
    <>
      <div>
        <a href="https://twitter.com/jcastaneyra" target="_blank" className={style.icon}>
          <Icon style={{ cursor: 'pointer' }} size={24} d={twitterIcon} />
        </a>
        <a href="https://www.linkedin.com/in/jcastaneyra/" target="_blank" className={style.icon}>
          <Icon style={{ cursor: 'pointer' }} size={24} d={linkedInIcon} />
        </a>
        <a href="https://github.com/jcastaneyra/" target="_blank" className={style.icon}>
          <Icon style={{ cursor: 'pointer' }} size={24} d={githubIcon} />
        </a>
        <a href="https://medium.com/@jcastaneyra" target="_blank" className={style.icon}>
          <Icon style={{ cursor: 'pointer' }} size={24} d={mediumIcon} />
        </a>
        <a href="https://dev.to/jcastaneyra" target="_blank" className={style.icon}>
          <Icon style={{ cursor: 'pointer' }} size={24} d={devIcon} />
        </a>
      </div>
    </>
  )
}


export default Media
